import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import Cookies from 'js-cookie';
import ThemeSelector from './ThemeSelector';
import { minimatch } from 'minimatch';

// Whitelist of pages that can show simulations
const whitelist = ['/', 'sim/**', '/home', '/about', '/contact', '/simulations'];

const debug_themes = [
  'asteroids', 'cube_reveal', 'terminal', 'xagario', 'enter_the_password', 
  'dust_game', 'quantum_wave_collapse', 'fractal_background', 'fluid_sim', 
  'your_ad_here', 'tictactoltage', 'hidden_game', 'blindfold_gauntlet', 
  'sound_bounce', 'default_null'
];

let themes = ['chess'];

// Check if the debug cookie exists
let debug = Cookies.get('debug');

// If the debug cookie doesn't exist, set it to false
if (debug === undefined) {
  debug = false;
  Cookies.set('debug', 'false', { expires: 1 }); // Expires in 1 day
} else {
  // Convert the cookie value to a boolean
  debug = debug === 'true';
}

if (debug) {
  themes = themes.concat(debug_themes);
}

const themeDescriptions = {
  'asteroids': 'A theme inspired by the classic Asteroids game.',
  'cube_reveal': 'A theme with a cube reveal animation.',
  'chess': 'Chess sim. Refresh page to re-randomize. Pieces capture others to reproduce.',
  'terminal': 'A theme that looks like a terminal.',
  'xagario': 'A theme inspired by the game Agar.io.',
  'enter_the_password': 'A theme with a password entry animation.',
  'dust_game': 'A theme with a dust particle simulation.',
  'quantum_wave_collapse': 'A theme based on quantum wave collapse.',
  'sound_bounce': 'Lclick: draw lines. Rclick: Drop balls, hold to drop many. For now, has bugs. Refresh when audio glitches.',
  'fractal_background': 'A theme with a fractal background.',
  'fluid_sim': 'A theme with fluid simulation.',
  'your_ad_here': 'A theme with placeholder ads.',
  'tictactoltage': 'A theme based on Tic-Tac-Toe.',
  'hidden_game': 'A theme with a hidden game.',
  'blindfold_gauntlet': 'A theme with a blindfold challenge.'
};

const initialSettings = {
  'chess': {
    cellSize: 50,
    piecePlacementProbability: 0.2,
    pieceMoveProbability: 0.05,
    wallPlacementProbability: 0.1,
    DISABLE_RIGHTCLICK_MENU: true
  },
  'sound_bounce': {
    // Add initial settings for sound_bounce theme here
  }
};

function getRandomTheme() {
  return themes[Math.floor(Math.random() * themes.length)];
}

// Configuration object mapping themes to their respective files
const themeFiles = {
  'chess': ['theme.css', 'theme.js', 'additionalFile1.js'],
  'sound_bounce': ['theme.css', 'theme.js', 'additionalFile1.js', 'additionalFile2.js'],
  'blindfold_gauntlet': ['theme.css', 'theme.js', 'engine.js', 'utils.js', 'boardViewer.js', 'board.js', 'piece.js', 'stockfish.js', 'stockfishEngine.js'],
  'terminal':['theme.css', 'commands.js', 'main.js', 'simulations.js','constants.js'],
  'asteroids':['theme.css','theme.js'],
	// Add other themes and their files here
};

// Default files for themes not explicitly defined
const defaultThemeFiles = ['theme.css', 'theme.js'];

function loadTheme(theme) {
  const files = themeFiles[theme] || defaultThemeFiles;
  files.forEach(file => {
    const fileExtension = file.split('.').pop();
    if (fileExtension === 'css') {
      const cssLink = document.createElement('link');
      cssLink.rel = 'stylesheet';
      cssLink.href = `${process.env.PUBLIC_URL}/themes/${theme}/${file}`;
      document.head.appendChild(cssLink);
    } else if (fileExtension === 'js') {
      const jsScript = document.createElement('script');
      jsScript.type = "module";
      jsScript.src = `${process.env.PUBLIC_URL}/themes/${theme}/${file}`;
      document.body.appendChild(jsScript);
    }
  });
}

function removeThemeCookieAndReload() {
  Cookies.remove('theme');
  window.location.reload();
}

// Function to check if the current page is whitelisted
function isPageWhitelisted(pathname) {
  return whitelist.some(pattern => minimatch(pathname, pattern));
}

const themeCookie = Cookies.get('theme');
let theme;

if (themeCookie) {
  theme = themeCookie;
} else {
  theme = getRandomTheme();
  Cookies.set('theme', theme, { expires: 1 }); // Expires in 1 day
}

// Check if the current page is whitelisted before loading the theme
const isWhitelisted = isPageWhitelisted(window.location.pathname);

if (isWhitelisted) {
  loadTheme(theme);
}

// Function to get the parent URL
function getParentUrl(pathname) {
  if (pathname === '/') return '/';
  const parts = pathname.split('/').filter(part => part);
  if (parts.length === 0) return '/';
  parts.pop();
  return '/' + parts.join('/');
}

function AppWrapper({ isWhitelisted }) {
  useEffect(() => {
    if (!isWhitelisted) {
      // Remove any classes or elements that could minimize or hide the content
      document.body.classList.remove('minimized');
      const minimizeButton = document.querySelector('.minimize-button');
      if (minimizeButton) {
        minimizeButton.style.display = 'none';
      }
    }
  }, [isWhitelisted]);

  return (
    <div className={isWhitelisted ? 'whitelisted_page' : ''}>
      {isWhitelisted && (
        <ThemeSelector 
          themes={themes} 
          themeDescriptions={themeDescriptions} 
          settings={initialSettings} 
          onSettingsChange={(theme, newSettings) => {
            initialSettings[theme] = newSettings;
            // Apply the new settings to the theme's JS in real-time
            if (theme === 'chess') {
              window.cellSize = newSettings.cellSize;
              window.piecePlacementProbability = newSettings.piecePlacementProbability;
              window.pieceMoveProbability = newSettings.pieceMoveProbability;
              window.wallPlacementProbability = newSettings.wallPlacementProbability;
              window.DISABLE_RIGHTCLICK_MENU = newSettings.DISABLE_RIGHTCLICK_MENU;
            }
            // Add similar logic for other themes
          }} 
        />
      )}
      <nav>
        <a href={getParentUrl(window.location.pathname)}>Up</a>
        <a href="/">Home</a>
      </nav>
      <App removeThemeCookieAndReload={removeThemeCookieAndReload} />
    </div>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AppWrapper isWhitelisted={isWhitelisted} />
  </React.StrictMode>
);
