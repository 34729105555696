import React from 'react';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import HtmlPage from './HtmlPage';


function App({ removeThemeCookieAndReload }) {

  return (

    <Router>

      <div className="App">

        <header className="App-header">


        </header>

        <main>

          <Routes>

            <Route class="mainpage" path="/" element={<HtmlPage />} />

            <Route path="/*" element={<HtmlPage />} />

          </Routes>

        </main>

      </div>

    </Router>

  );

}


export default App;
