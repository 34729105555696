import React, { useState, useEffect } from 'react';

const ThemeSettings = ({ theme, settings, onSettingsChange }) => {
  const [localSettings, setLocalSettings] = useState(settings[theme] || {});

  useEffect(() => {
    setLocalSettings(settings[theme] || {});
  }, [theme, settings]);

  const handleChange = (key, value) => {
    const updatedSettings = { ...localSettings, [key]: value };
    setLocalSettings(updatedSettings);
    onSettingsChange(theme, updatedSettings);
  };

  if (theme === 'chess') {
    return (
      <div style={{ position: 'absolute', top: '10px', left: '10px', backgroundColor: '#fff', padding: '10px', borderRadius: '5px' }}>
        <h3>Chess Theme Settings</h3>
        <label>
          Cell Size:
          <input
            type="range"
            min="50"
            max="100"
            value={localSettings.cellSize || 50}
            onChange={(e) => handleChange('cellSize', parseInt(e.target.value))}
          />
        </label>
        <br />
        <label>
          Piece Placement Probability:
          <input
            type="range"
            min="0"
            max="1"
            step="0.01"
            value={localSettings.piecePlacementProbability || 0.2}
            onChange={(e) => handleChange('piecePlacementProbability', parseFloat(e.target.value))}
          />
        </label>
        <br />
        <label>
          Piece Move Probability:
          <input
            type="range"
            min="0"
            max="1"
            step="0.01"
            value={localSettings.pieceMoveProbability || 0.05}
            onChange={(e) => handleChange('pieceMoveProbability', parseFloat(e.target.value))}
          />
        </label>
        <br />
        <label>
          Wall Placement Probability:
          <input
            type="range"
            min="0"
            max="1"
            step="0.01"
            value={localSettings.wallPlacementProbability || 0.1}
            onChange={(e) => handleChange('wallPlacementProbability', parseFloat(e.target.value))}
          />
        </label>
        <br />
        <label>
          Disable Right Click Menu:
          <input
            type="checkbox"
            checked={localSettings.DISABLE_RIGHTCLICK_MENU || false}
            onChange={(e) => handleChange('DISABLE_RIGHTCLICK_MENU', e.target.checked)}
          />
        </label>
      </div>
    );
  }

  if (theme === 'sound_bounce') {
    return (
      <div style={{ position: 'absolute', top: '10px', left: '10px', backgroundColor: '#fff', padding: '10px', borderRadius: '5px' }}>
        <h3>Sound Bounce Theme Settings</h3>
        {/* Add relevant settings for the sound_bounce theme here */}
      </div>
    );
  }

  return null;
};

export default ThemeSettings;
