import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import Cookies from 'js-cookie';



function HtmlPage() {

  const { '*': pagePath } = useParams();

  const [content, setContent] = useState('');

  const [isMinimized, setIsMinimized] = useState(Cookies.get('isMinimized') === 'true');



  useEffect(() => {

    const fetchHtml = async () => {

      try {

        const path = pagePath ? pagePath : 'home';

        const response = await fetch(`${process.env.PUBLIC_URL}/pages/${path}.html`);

        if (response.ok) {

          const html = await response.text();

          setContent(html);

        } else {

          setContent('<h1>Page not found</h1>');

        }

      } catch (error) {

        setContent('<h1>Error loading page</h1>');

      }

    };



    fetchHtml();

  }, [pagePath]);



  const toggleMinimize = () => {

    const newMinimizedState = !isMinimized;

    setIsMinimized(newMinimizedState);

    Cookies.set('isMinimized', newMinimizedState, { expires: 1 });

  };



  return (
    <div>
    
    <button className="minimize-button" onClick={toggleMinimize}>

        {isMinimized ? '+' : '-'}

    </button>

    <div className={`page_content ${isMinimized ? 'minimized' : ''}`}>

      <div dangerouslySetInnerHTML={{ __html: content }} />

    </div>

    </div>

  );

}



export default HtmlPage;
