import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import ThemeSettings from './ThemeSettings';

const ThemeSelector = ({ themes, themeDescriptions, settings, onSettingsChange }) => {
  const [selectedTheme, setSelectedTheme] = useState(Cookies.get('theme') || '');
  const [showDescription, setShowDescription] = useState(false);
  const [showSettings, setShowSettings] = useState(false);

  const handleThemeChange = (event) => {
    const selectedTheme = event.target.value;
    setSelectedTheme(selectedTheme);
    setShowDescription(false); // Hide description when theme changes
    Cookies.set('theme', selectedTheme, { expires: 1 });
    window.location.reload();
  };

  const handleDescriptionToggle = () => {
    setShowDescription(!showDescription);
  };

  const handleSettingsToggle = () => {
    setShowSettings(!showSettings);
  };

  return (
    <div style={{ position: 'absolute', top: '10px', right: '10px' }}>
      <button onClick={handleSettingsToggle} style={{ marginRight: '10px' }}>
        (s)
      </button>
      <select onChange={handleThemeChange} value={selectedTheme}>
        <option value="" disabled>Select Theme</option>
        {themes.map((theme) => (
          <option key={theme} value={theme}>
            {theme}
          </option>
        ))}
      </select>
      <button onClick={handleDescriptionToggle} style={{ marginLeft: '10px' }}>
        (?)
      </button>
      {showDescription && selectedTheme && (
        <div style={{ marginTop: '10px', backgroundColor: '#f0f0f0', padding: '10px', borderRadius: '5px' }}>
          {themeDescriptions[selectedTheme]}
        </div>
      )}
      {showSettings && selectedTheme && (
        <ThemeSettings theme={selectedTheme} settings={settings} onSettingsChange={onSettingsChange} />
      )}
    </div>
  );
};

export default ThemeSelector;
